<!--
 * @Author: ChaiHongJun
 * @Date: 2021-06-02 17:48:02
 * @LastEditors: ChaiHongJun
 * @LastEditTime: 2021-06-15 10:40:19
 * @version: 
 * @Description: 前一篇 后一篇
-->
<template>
  <a-skeleton
    :loading="loadingPrevNext"
    :active="loadingPrevNext"
    :paragraph="{ rows: 1 }"
  >
    <nav class="article-nav">
      <span class="article-nav-prev"
        >上一篇<br />
        <a
          rel="prev"
          @click="toDetail(parseInt(articleID) - 1)"
          :disabled="prev.id === 0 ? true : false"
          >{{ prev.title }}</a
        ></span
      >
      <span class="article-nav-next"
        >下一篇<br />
        <a
          rel="next"
          @click="toDetail(parseInt(articleID) + 1)"
          :disabled="next.id === 0 ? true : false"
          >{{ next.title }}</a
        ></span
      >
    </nav>
  </a-skeleton>
</template>
<script>
import { getPrevNext } from "@/request/index.js";
import { Message } from "element-ui";
export default {
  name: "articleNav",
  data() {
    return {
      loadingPrevNext: true,
      articleID: this.$route.params.id,
      prev: {},
      next: {},
    };
  },
  watch: {
    $route: {
      handler: "reloadPage",
    },
  },
  methods: {
    reloadPage() {
      // console.log(" reloadPage 当前文章ID：" + this.articleID);
      // console.log("this.prev:");
      // console.log(this.prev);
      // console.log("this.next:");
      // console.log(this.next);
      this.articleID = this.$route.params.id;
      //请求当前页面的上一篇下一篇数据
      this.loadingPrevNext = true;
      getPrevNext(this.articleID)
        .then((res) => {
          this.prev = res.data[0];
          this.next = res.data[1];
          this.loadingPrevNext = false;
        })
        .catch((err) => {
          Message.error("reloadPage loadingPrevNext" + err);
          this.loadingPrevNext = false;
          console.log(err);
        });

      // console.log("当前文章ID:" + this.articleID);
      // this.articleID = this.$route.params.id;
    },
    toDetail(id) {
      // console.log("前往文章ID：" + id);
      this.$router.push({ name: "Detail", params: { id } });
    },
  },
  mounted() {
    // console.log(" created 当前文章ID：" + this.articleID);
    // console.log("this.prev:");
    // console.log(this.prev);
    // console.log("this.next:");
    // console.log(this.next);
    this.articleID = this.$route.params.id;
    this.loadingPrevNext = true;
    getPrevNext(this.articleID)
      .then((res) => {
        this.prev = res.data[0];
        this.next = res.data[1];
        this.loadingPrevNext = false;
      })
      .catch((err) => {
        Message.error("mounted loadingPrevNext" + err);
        this.loadingPrevNext = false;
        console.log(err);
      });
  },
};
</script>
<style>
</style>