<!--
 * @Author: ChaiHongJun
 * @Date: 2021-06-02 17:35:31
 * @LastEditors: ChaiHongJun
 * @LastEditTime: 2021-06-11 17:17:46
 * @version: 
 * @Description: 文章详情相关内容
-->
<template>
  <div class="relates">
    <div class="title"><h3>相关推荐</h3></div>
    <ul v-if="localRelates.length > 0">
      <li v-for="(item, index) of localRelates" :key="index">
        <a :title="item.title" @click="toDetail(item.id)">{{ item.title }}</a>
      </li>
    </ul>
    <div class="panel-body" v-else>暂时没有</div>
  </div>
</template>

<script>
export default {
  name: "Relates",
  props: ["relates"],
  data() {
    return {
      localRelates: this.relates,
    };
  },
  watch: {
    relates() {
      this.localRelates = this.relates;
    },
  },
  methods: {
    toDetail(id) {
      this.$router.push({ name: "Detail", params: { id } });
    },
  },
};
</script>

<style>
</style>