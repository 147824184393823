<!--
 * @Author: ChaiHongJun
 * @Date: 2021-06-02 14:23:13
 * @LastEditors: ChaiHongJun
 * @LastEditTime: 2021-06-25 09:08:41
 * @version: 
 * @Description: 
-->
<template>
  <section class="container">
    <div class="content-wrap single">
      <div class="content">
        <a-skeleton
          :loading="loadingDetail"
          :active="loadingDetail"
          :paragraph="{ rows: 15 }"
        >
          <header class="article-header">
            <h1 class="article-title">
              <a href="">{{ detail.title }}</a>
            </h1>
            <div class="article-meta">
              <span class="item">{{ detail.publish_time }}</span>
              <span class="item"
                >分类：<a rel="category tag" @click="toRoute(detail.cid)">{{
                  detail.categoryName
                }}</a></span
              >
              <span class="item post-views">阅读({{ detail.reading }})</span>
              <span class="item"></span>
            </div>
          </header>
          <article class="article-content" v-html="detail.content">
            <!-- <pre>
        <code v-html="detail.content"></code>
           </pre> -->
          </article>

          <div class="post-copyright">
            本网站内容除特别注明皆为原创，版权归原作者所有，如需转载请联系本站，本站转载内容其版权也均归原作者所有；
            本站原创及转载至本网站的信息，仅供参考，本网站将尽力确保转载信息的完整性，若原文已有修改本站将不另行修改；
            如原作者对本网站转载文章有疑问，请及时联系本网站，本网站将积极维护著作权人的合法权益。
          </div>
          <!-- <div class="action-share">
            <div
              class="bdsharebuttonbox bdshare-button-style0-24"
              data-bd-bind="1593570939522"
            >
              <span>分享到：</span>
              <div class="bshare-custom">
                <div class="bsPromo bsPromo2"></div>
                <a title="分享到QQ好友" class="bshare-qqim"></a>
                <a title="分享到QQ空间" class="bshare-qzone"></a>
                <a title="分享到新浪微博" class="bshare-sinaminiblog"></a>
                <a title="分享到腾讯微博" class="bshare-qqmb"></a>
                <a title="分享到微信" class="bshare-weixin"></a>
                <a
                  title="更多平台"
                  class="bshare-more bshare-more-icon more-style-addthis"
                ></a>
              </div>
            </div>
          </div> -->
        </a-skeleton>
        <!-- <div class="article-tags">
          标签：
          <a href="/search/seaTxt/%E7%A6%BB%E5%A9%9A.html" rel="tag">离婚</a>
        </div> -->
        <ArticleNav></ArticleNav>
        <!-- 文章底部tag标签 -->
        <!-- <DetailTag></DetailTag> -->
        <a-skeleton
          :loading="loadingRelate"
          :active="loadingRelate"
          :paragraph="{ rows: 5 }"
        >
          <Relate :relates="relates"></Relate>
        </a-skeleton>
      </div>
    </div>
    <div class="sidebar">
      <a-skeleton
        :loading="loadingSticky"
        :active="loadingSticky"
        :paragraph="{ rows: 5 }"
      >
        <Sidebar siderName="置顶推荐" :dataList="Sticky"></Sidebar>
      </a-skeleton>
      <a-skeleton
        :loading="loadingRand"
        :active="loadingRand"
        :paragraph="{ rows: 5 }"
      >
        <Sidebar siderName="随机推荐" :dataList="Rand"></Sidebar
      ></a-skeleton>
    </div>
  </section>
</template>
<script>
import Sidebar from "@/components/common/sidebar/Index.vue";
import Relate from "@/components/detail/relevant/Index.vue";
// import DetailTag from "@/components/detail/tag/Index.vue";
import ArticleNav from "@/components/detail/articleNav/Index.vue";
import {
  getDetail,
  getRelatedArticles,
  getStickyList,
  getRandList,
} from "@/request/index.js";
import { Message } from "element-ui";
export default {
  name: "Detail",
  components: { Sidebar, Relate, ArticleNav },
  data() {
    return {
      Sticky: [],
      Rand: [],
      detail: {},
      relates: [],
      loadingDetail: true,
      loadingRelate: true,
      loadingSticky: true,
      loadingRand: true,
      limit: 4,
    };
  },
  watch: {
    $route: {
      handler: "reloadPage",
    },
  },
  methods: {
    toRoute(id) {
      console.log("栏目ID：" + id);
      this.$router.push({ name: "Category", params: { id } });
    },
    reloadPage() {
      this.loadingDetail = true;
      this.loadingRelate = true;
      this.loadingRand = true;
      // 请求内容详情
      getDetail(this.$route.params.id)
        .then((res) => {
          this.detail = res.data;
          this.loadingDetail = false;
        })
        .catch((err) => {
          Message.error(err);
          console.log("loadingDetail reloadPage" + err);
          this.loadingDetail = false;
        });
      //请求相关文章
      getRelatedArticles(this.$route.params.id)
        .then((res) => {
          this.relates = res.data;
          this.loadingRelate = false;
        })
        .catch((err) => {
          Message.error("loadingRelate reloadPage" + err);
          console.log(err);
          this.loadingRelate = false;
        });
      //加载侧栏数据
      getStickyList(this.cateIDsidebar, this.limit / 2, 0)
        .then((res) => {
          this.Sticky = res.data;
          this.loadingSticky = false;
        })
        .catch((err) => {
          console.log("err " + err);
          Message.error("loadingSticky reloadPage" + err);
          this.loadingSticky = false;
        });
      /* 加载侧栏 随机 内容 数据*/
      getRandList(this.cateIDsidebar, this.limit / 2, 0)
        .then((res) => {
          this.Rand = res.data;
          this.loadingRand = false;
        })
        .catch((err) => {
          console.log("err " + err);
          Message.error("loadingRand reloadPage" + err);
          this.loadingRand = false;
        });
    },
    // convert(str) {
    //   this.$refs.content.innerText = str;
    // },
  },
  computed: {
    // getContent() {
    //   return (str) => {
    //     return this.convert(str);
    //   };
    // },
  },
  created() {
    //加载正文数据
    this.loadingDetail = true;
    this.loadingRelate = true;
    this.loadingRand = true;
    getDetail(this.$route.params.id)
      .then((res) => {
        this.detail = res.data;
        this.loadingDetail = false;
      })
      .catch((err) => {
        Message.error("loadingDetail reloadPage" + err);
        console.log(err);
        this.loadingDetail = false;
      });
    //请求相关文章
    getRelatedArticles(this.$route.params.id)
      .then((res) => {
        this.relates = res.data;
        this.loadingRelate = false;
      })
      .catch((err) => {
        Message.error("loadingRelate reloadPage" + err);
        console.log(err);
        this.loadingRelate = false;
      });
    //加载侧栏数据
    getStickyList(this.cateIDsidebar, this.limit / 2, 0)
      .then((res) => {
        this.Sticky = res.data;
        this.loadingSticky = false;
      })
      .catch((err) => {
        console.log("err " + err);
        Message.error("loadingSticky reloadPage" + err);
        this.loadingSticky = false;
      });
    /* 加载侧栏 随机 内容 数据*/
    getRandList(this.cateIDsidebar, this.limit / 2, 0)
      .then((res) => {
        this.Rand = res.data;
        this.loadingRand = false;
      })
      .catch((err) => {
        console.log("err " + err);
        Message.error("loadingRand reloadPage" + err);
        this.loadingRand = false;
      });
  },
};
</script>
